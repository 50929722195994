@import "../../Brakpoints.scss";

.reactions {
  width: 244px;
  background: url("/assets/images/emoji/emoji_background.png") no-repeat right
    center;
  background-size: 100% 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 10px;
  padding: 15px 22px;
  position: relative;

  transition: 0.5s all;
  right: 0px;
  opacity: 1;

  &.working {
    opacity: 0.1;
  }

  @media (max-width: $mobile) {
  }

  .icn {
    position: relative;
    width: 45px;
    height: 45px;
    background-size: contain;
    cursor: pointer;

    .clicked {
      width: 45px;
      height: 45px;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;

      &.hide {
        display: none !important;
      }
      &.cl0 {
        animation: emojiAnimation 2.5s linear;
      }
      &.cl1 {
        animation: emojiAnimation2 2.5s linear;
      }
      &.cl2 {
        animation: emojiAnimation3 2.5s linear;
      }

      backface-visibility: hidden;
      // @for $i from 1 through 5 {
      //   &.cl#{$i} {
      //     animation-delay: calc(#{$i}s / 0.5);
      //   }
      // }
    }

    &.happy {
      background: url("/assets/images/emoji/happy.png") no-repeat center;
      .clicked {
        background: url("/assets/images/emoji/happy.png") no-repeat center;
      }
    }
    &.sad {
      background: url("/assets/images/emoji/sad.png") no-repeat center;
      .clicked {
        background: url("/assets/images/emoji/sad.png") no-repeat center;
      }
    }
    &.clap {
      background: url("/assets/images/emoji/clap.png") no-repeat center;
      .clicked {
        background: url("/assets/images/emoji/clap.png") no-repeat center;
      }
    }
  }
}

@keyframes emojiAnimation {
  10% {
    left: 10px;
    top: -20px;
  }
  30% {
    left: 30px;
    top: -60px;
  }
  40% {
    left: 40px;
    top: -80px;
  }
  60% {
    left: 00px;
    top: -120px;
    opacity: 0.7;
  }
  70% {
    left: 00px;
    top: -140px;
    opacity: 0.8;
  }
  80% {
    left: -40px;
    top: -160px;
    opacity: 0.85;
  }
  100% {
    left: 0px;
    top: -250px;
    opacity: 0;
  }
}

@keyframes emojiAnimation2 {
  10% {
    left: -10px;
    top: -10px;
  }
  30% {
    left: 0px;
    top: -40px;
  }
  40% {
    left: -10px;
    top: -70px;
  }
  60% {
    left: 30px;
    top: -100px;
    opacity: 0.7;
  }
  70% {
    left: 00px;
    top: -130px;
    opacity: 0.8;
  }
  80% {
    left: 40px;
    top: -150px;
    opacity: 0.85;
  }
  100% {
    left: 0px;
    top: -170px;
    opacity: 0;
  }
}

@keyframes emojiAnimation3 {
  10% {
    left: -10px;
    top: -20px;
  }
  30% {
    left: 10px;
    top: -50px;
  }
  40% {
    left: 0px;
    top: -70px;
  }
  60% {
    left: -30px;
    top: -100px;
    opacity: 0.7;
  }
  70% {
    left: 00px;
    top: -130px;
    opacity: 0.8;
  }
  80% {
    left: 40px;
    top: -150px;
    opacity: 0.85;
  }
  100% {
    left: 0px;
    top: -200px;
    opacity: 0;
  }
}
