@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  font-family: "Montserrat", sans-serif;

  .grecaptcha-badge {
    width: 69px !important;
    height: 60px;
    display: block;
    transition: right 0.3s ease 0s;
    position: fixed;
    bottom: 20px !important;
    left: 0px;
    box-shadow: grey 0px 0px 5px;
    border-radius: 2px;
    overflow: hidden;
  
    &:hover {
      width: 241px !important;
      //left: -186px !important;
    }
  }
}
input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  box-sizing: border-box;
}

.Toastify__toast-body {
  // text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 18px;
}

.layers {
  background-image: URL("./assets/images/background_1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  overflow-y: auto;
}

//TODO Remove this
.temp-bingo-window {
  button {
    padding: 5px;
    margin-bottom: 10px;
    width: 100%;
  }

  .container {
    max-width: 1000px;
    margin: 0 auto;
  }
}

//animations
.rotate {
  animation: rotation 20s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.footer-x {
  display: none;
  position: fixed;
  bottom: 0px;
  color: white;
  overflow: hidden;
  left: 2px;
  opacity: 0.3;
  font-size: 10px;
}

.game-info {
  max-width: 800px;
  position: absolute;
  left: 22px;
  top: 53px;
  background: white;
  padding: 15px 30px;
  border-radius: 15px;
  margin-right: 25px;
  z-index: 99;
  // border: 5px solid #570355;
  overflow: hidden;

  .title {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #42023e;
  }
  ul {
    li {
      padding: 10px 0;
      font-weight: 500;
      color: #242424;
      line-height: 20px;
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    span {
      color: #4e0249;
      font-size: 40px;
    }
  }
}

.slick-list {
  overflow: visible;
}
.slick-slide {
  text-align: center;
}
.slick-next,
.slick-prev {
  z-index: 99;
  width: 50px;
  height: 50px;
}
.slick-next:before,
.slick-prev:before {
  font-size: 47px;
  line-height: 1;
  opacity: 1;
  color: #5e156d;
  background: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  box-shadow: 2px 3px 7px 1px black;
  position: relative;
}

a {
  text-decoration: none;
}

.rotate-dev-msg {
  display: none;
}
@media screen and (orientation: landscape) {
  .rotate-dev-msg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #673ab7f0;
    color: white;
    z-index: 9991;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 25px;
  }
}

