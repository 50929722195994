@import "../../Brakpoints.scss";

.game-page {
  display: grid;
  height: 100vh;
  grid-template-columns: 200px auto 200px;
  grid-template-rows: 60px auto 20px;
  grid-template-areas: "top top top" "leftBar cardWrapper rightBar" ". . .";
  z-index: 1;
  position: relative;

  @media (max-width: $bingo) {
    grid-template-columns: 100px auto 100px;
    grid-template-areas: "leftBar top rightBar" "cardWrapper cardWrapper cardWrapper" ". . .";
  }

  .main-area {
    grid-area: cardWrapper;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "question"
      "cardWrapper";
    justify-items: center;
    align-items: start;
    position: relative;
    width: 555px;
    align-self: center;
    justify-self: center;

    @media (max-width: $bingo) {
      align-self: flex-end;
      width: 100%;
      align-self: center;
      margin-top: 75px;
      grid-template-areas:
        "podium"
        "question"
        "cardWrapper";
    }

    .podium {
      grid-template-areas: podium;
      background-image: URL("../../assets/images/themes/theme_80s.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-position-y: 40px;

      position: absolute;
      top: -70px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      margin: 0 auto;
      pointer-events: none;

      @media (max-width: $bingo) {
        // position: relative;
        // width: 100%;
        // height: 200px;
        // top: -52px;
        // background-size: 130%;
        // background-position: 50% 15%;
        position: absolute;
        width: 600px;
        height: 300px;
        top: -180px;
      }

      @media (max-width: $mobile) {
        top: -120px;
        width: 400px;
      }

      @media (max-width: $smallMobile) {
        top: -55px;
        width: 300px;
      }

      @media (max-width: 400) {
        position: absolute;
        width: 320px;
        height: 200px;
        top: -20px;
        background-size: contain;
        background-position: bottom;
      }

      .counterBar {
        position: absolute;
        top: 199px;
        left: -5px;
        width: 53px;
        height: 53px;
        z-index: 5;
        right: 0;
        margin: 0 auto;

        transform: scaleX(-1);

        @media (max-width: $bingo) {
          top: 198px;
          left: -2px;
          width: 50px;

          // top: 144px;
          // left: -4px;
          // width: 40px;
        }

        @media (max-width: $mobile) {
          top: 155px;
          left: -2px;
          width: 37px;
        }

        @media (max-width: $smallMobile) {
          top: 126px;
          left: -2px;
          width: 28px;
        }

        // @media (max-width: $bingo) and (min-width: 400px) {
        //   top: 128px;
        //   left: -1px;
        //   width: 37px;
        // }

        // @media (max-width: 400) {
        //   top: 144px;
        //   left: -4px;
        //   width: 40px;
        // }
      }
    }

    .question {
      grid-area: question;
      color: white;
      font-weight: bold;
      font-size: 23px;
      text-shadow: 15px -5px 5px #0000009c;
      text-align: center;
      z-index: 1;
      line-height: 32px;

      bottom: -20px;
      position: relative;

      @media (max-width: $bingo) {
        bottom: auto;
        padding: 5px 10px;
      }
    }

    .card-wrapper {
      grid-area: cardWrapper;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: stretch;
      max-width: 1000px;
      width: 100%;
      position: relative;

      .cwrap {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        // margin-top: 120px;
        // margin-bottom: 20px;
        position: relative;
        width: 450px;
        margin: 160px auto 20px;

        @media (max-width: $bingo) {
          margin-top: 35px;
        }

        .shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          transform: perspective(10px) rotateX(1deg);
          // background: radial-gradient(
          //   circle,
          //   rgba(255, 255, 255, 0) 56%,
          //   #ffffff57 99%
          // );
          // box-shadow: 1px 2px 2px #ffffff17;
          padding: 25px;
          top: -20px;

          @media (max-width: $bingo) {
            display: none;
            transform: none;
          }
        }
        .card {
          width: 100%;
          height: 350px;
          background-color: transparent;
          border: none;
          border-radius: 0;
          margin: 0 10px 0 10px;
          cursor: pointer;
          z-index: 5;

          @media (max-width: $bingo) {
            transform: none;
          }

          &.left,
          &.right {
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: background-image 0.35s;
            backface-visibility: hidden;

            @media (max-width: $bingo) {
              max-width: 200px;
            }

            .bar-chart {
              display: none;
              position: absolute;
              bottom: 0;
              height: 150px;
              background: red;
              width: 100%;

              transform: perspective(500px) rotateY(0deg) rotateZ(2deg)
                rotateX(45deg);

              .inner {
                position: absolute;
                background: white;
                width: 100%;
                height: 100%;
                bottom: 0;
              }
            }

            .text {
              color: #7b78ff;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              // padding-bottom: 30px;
              // padding-left: 12px;
              // padding-right: 12px;
              padding: 12px;
              letter-spacing: -0.5px;
              line-height: 22px;
              width: 185px;
              height: 230px;
              margin-bottom: 5px;
              //margin-left: 40px;
              z-index: 6;
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;

              .label {
                position: absolute;
                top: -1px;
                left: auto;
                right: auto;
                color: white;
                padding: 5px 7px;
                border-radius: 0 0 3px 3px;
              }
            }

            .wrong,
            .correct {
              .text {
                display: none;
              }
            }
          }

          &.left {
            background-image: URL("../../assets/images/cards/card_blank_new.png");
            &.active {
              background-image: URL("../../assets/images/cards/card_selected.png");
            }
            &.active.correct,
            &.correct {
              background-image: URL("../../assets/images/cards/card_correct.png");
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_correct.png");
              }
              .text {
                display: none;
              }
            }
            &.active.correct-blank,
            &.correct-blank {
              background-image: URL("../../assets/images/cards/card_correct_blank.png");
              .text {
                color: #0e6f2f;
              }
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_correct_blank.png");
                .text {
                  color: #0e6f2f;
                }
              }
            }
            &.active.wrong,
            &.wrong {
              background-image: URL("../../assets/images/cards/card_wrong.png");
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_wrong.png");
              }

              .text {
                display: none;
              }
            }
            &.active.wrong-blank,
            &.wrong-blank {
              background-image: URL("../../assets/images/cards/card_wrong_blank.png");
              .text {
                color: #8f1a0b;
              }
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_wrong_blank.png");
                .text {
                  color: #8f1a0b;
                }
              }
            }

            @media (max-width: $bingo) {
              background-image: URL("../../assets/images/mobile/card_blank.png");
              .text {
                transform: none;
                margin: 0;
              }
              &.active {
                background-image: URL("../../assets/images/mobile/card_selected.png");
              }
            }

            &.correct-blank .label {
              background-color: #0e6f2f;
            }

            &.wrong-blank .label {
              background-color: #c62215;
            }

            .label {
              // transform: perspective(300px) rotateY(-20deg) rotateZ(0deg)
              //   rotateX(43deg);
              // left: 62px !important;
              // top: 10px !important;
              @media (max-width: $bingo) {
                transform: none;
               // left: 51px !important;
                top: -10px !important;
              }
            }
          }

          &.right {
            background-image: URL("../../assets/images/cards/card_blank_new.png");
            &.active {
              background-image: URL("../../assets/images/cards/card_selected.png");
            }
            &.active.correct,
            &.correct {
              background-image: URL("../../assets/images/cards/card_correct.png");
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_correct.png");
              }
              .text {
                display: none;
              }
            }
            &.active.correct-blank,
            &.correct-blank {
              background-image: URL("../../assets/images/cards/card_correct_blank.png");
              .text {
                color: #0e6f2f;
              }
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_correct_blank.png");
                .text {
                  color: #0e6f2f;
                }
              }
            }
            &.active.wrong,
            &.wrong {
              background-image: URL("../../assets/images/cards/card_wrong.png");
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_wrong.png");
              }
              .text {
                display: none;
              }
            }
            &.active.wrong-blank,
            &.wrong-blank {
              background-image: URL("../../assets/images/cards/card_wrong_blank.png");
              .text {
                color: #8f1a0b;
              }
              @media (max-width: $bingo) {
                background-image: URL("../../assets/images/mobile/card_wrong_blank.png");
                .text {
                  color: #8f1a0b;
                }
              }
            }
            .text {
              margin-left: 0;
              margin-right: 0px;
            }

            @media (max-width: $bingo) {
              background-image: URL("../../assets/images/mobile/card_blank.png");
              .text {
                transform: none;
                margin: 0;
              }
              &.active {
                background-image: URL("../../assets/images/mobile/card_selected.png");
              }
            }

            &.correct-blank .label {
              background-color: #0e6f2f;
            }

            &.wrong-blank .label {
              background-color: #c62215;
            }

            .label {
             // transform: perspective(300px) rotateY(21deg) rotateZ(0deg)
              //  rotateX(46deg);
              //left: 27px !important;
              //top: 10px !important;
              @media (max-width: $bingo) {
                transform: none;
              //  left: 46px !important;
                top: -10px !important;
              }
            }
          }
        }
      }
    }
  }

  .top {
    grid-area: top;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 5px;

    .answered,
    .remain {
      width: 18px;
      height: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 5px;
      color: transparent;
    }

    .answered {
      background-image: URL("../../assets/images/questionui_1.png");
    }
    .remain {
      background-image: URL("../../assets/images/questionui_2.png");
    }
    .current {
      font-size: 20px;
      color: #8f6b8c;
      font-weight: 600;
      margin-right: 5px;
      // margin-top: -8px;
    }
  }

  .footer {
    grid-area: footer1;
    
  }

  .left-bar,
  .right-bar {
    z-index: 1;
    position: relative;

    @media (max-width: $bingo) {
      transform: scale(0.7);
    }

    .podium {
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 50px;
      z-index: 1;
    }
    .img {
      width: 200px;
      height: 200px;
      z-index: 2;
      position: relative;
    }

    .content {
      top: 230px;
      z-index: 2;
      position: relative;

      .number {
        font-size: 45px;
        font-weight: 900;
        color: white;
        text-align: center;

        @media (max-width: $bingo) {
          font-size: 37px;
        }
      }

      .label {
        text-transform: uppercase;
        color: #490d4f;
        font-size: 17px;

        font-weight: 900;
        padding: 8px;
        border-radius: 8px;
        text-align: center;
      }
    }
  }

  .left-bar {
    grid-area: leftBar;
    .podium {
      background-image: URL("../../assets/images/podium_left.png");
      @media (max-width: $bingo) {
        display: none;
      }
      // @media (min-width: $lg) {
      //   left: -50px;
      //   right: -50px;
      // }
    }

    .img {
      background-image: URL("../../assets/images/prizefund_scenery.png");
      background-position-x: -35px;
      background-repeat: no-repeat;
      width: 100%;
      background-size: contain;

      @media (max-width: $bingo) {
        background-position-x: -10px;
        width: 100px;
      }
    }

    .content {
      position: absolute;
      right: -10px;
      transform: perspective(300px) rotateY(38deg) rotateZ(343deg)
        rotateX(351deg);
      top: 195px;

      @media (max-width: $bingo) {
        right: -25px;
        transform: perspective(300px) rotateY(8deg) rotateZ(351deg) rotateX(0);
        top: 77px;
      }

      .label {
        background: #ffd647;
        background: linear-gradient(90deg, #ffd647 0%, white 100%);
        box-shadow: -7px -7px 3px #0000006e;
      }

      .number {
        text-shadow: -7px -7px 3px #0000006e;
      }
    }
  }

  .right-bar {
    grid-area: rightBar;

    .podium {
      background-image: URL("../../assets/images/podium_right.png");
      left: -72px;
      right: 0px;
      bottom: 0px;
      top: 0;
      background-position-x: 50px;
      background-repeat: no-repeat;

      @media (max-width: $bingo) {
        display: none;
      }
    }

    .img {
      background-image: URL("../../assets/images/playersleft_scenery.png");
      background-position-x: 0;
      max-width: 165px;
      width: 100%;
      margin-left: 64px;
      top: 30px;
      background-repeat: no-repeat;
      background-size: contain;

      @media (max-width: $bingo) {
        top: 0;
        margin-left: 16px;
      }
    }

    .content {
      position: absolute;
      left: 9px;
      transform: perspective(300px) rotateY(331deg) rotateZ(19deg)
        rotateX(343deg);
      top: 194px;

      @media (max-width: $bingo) {
        left: -30px;
        transform: perspective(300px) rotateY(340deg) rotateZ(9deg)
          rotateX(351deg);
        top: 80px;
      }

      .label {
        background: #46aafa;
        background: linear-gradient(90deg, white 0%, #46aafa 100%);
        box-shadow: 7px -7px 3px #0000006e;
      }
      .number {
        text-shadow: -5px -5px 3px #0000006e;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    z-index: 9999;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .spectating {
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $mobile) {
        padding-left: 15px;
        
      }

      .icon {
        background: URL("../../assets/images/exit_button.png") no-repeat center;
        background-size: contain;
        width: 49px;
        height: 49px;
        position: relative;
        z-index: 2;
        cursor: pointer;
        display: none;

        @media (max-width: $mobile) {
          width: 40px;
          height: 40px;
        }
      }
      .text {
        max-width: 550px;
        height: 47px;
        background: URL("../../assets/images/spectate_background.png") no-repeat left
          center;
        background-size: 85% 100%;
        transform: translateX(-40px);
        padding-left: 50px;
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: white;
        text-transform: uppercase;

        span.mobile {
          display: none;
        }

        @media (max-width: $bingo) {
          font-size: 15px;
        }

        @media (max-width: $mobile) {
          font-size: 15px;
          padding-left: 45px;
          height: 40px;
          background: transparent;
          span.mobile {
            display: block;
          }
          span.desktop {
            display: none;
          }
          
        }

        .eye {
          width: 32px;
          height: 22px;
          background: URL("../../assets/images/spectate_icon.png") no-repeat center;
          background-size: contain;
          margin-right: 12px;

          @media (max-width: $bingo) {
            width: 25px;
            height: 15px;
            margin-right: 10px;
          }

          @media (max-width: $mobile) {
            width: 20px;
            margin-right: 5px;
          }
         
        }
        .label {
          text-transform: uppercase;
          color: white;
          font-size: 10px;
        }
      }
    }

    .earnings {
      padding-left: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: $mobile) {
        padding-left: 10px;
      }
      .icon {
        background: URL("../../assets/images/earnings_icon.png") no-repeat center;
        background-size: contain;
        width: 62px;
        height: 62px;
        position: relative;
        z-index: 2;
        @media (max-width: $mobile) {
          width: 45px;
          height: 45px;
        }
      }
      .text {
        width: 265px;
        height: 47px;
        background: URL("../../assets/images/earnings_background.png") no-repeat
          right center;
        background-size: 100% 100%;
        transform: translateX(-40px);
        padding-left: 50px;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @media (max-width: $mobile) {
          width: 180px;
          height: 40px;
          transform: none;
          padding-left: 40px;
          margin-left: -35px;
        }
        @media (max-width: $smallMobile) {
          width: 160px;
        }
        .amount {
          font-size: 25px;
          font-weight: 900;
          color: white;
          @media (max-width: $mobile) {
            font-size: 20px;
          }
        }
        .label {
          text-transform: uppercase;
          color: white;
          font-size: 10px;
          @media (max-width: $mobile) {
            font-size: 8.8px;
          }
        }
      }
    }

    .reactions {
      width: 244px;
      height: 59px;
      background: URL("../../assets/images/emoji/emoji_background.png") no-repeat
        right center;
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0 10px;
      padding: 0 22px;
      @media (max-width: $mobile) {
        width: 244px;
        height: 50px;
        grid-gap: 0 5px;
        gap: 0 5px;
        padding: 0 5px;
        background-size: 110% 100%;
      }
      .happy {
        width: 43px;
        height: 45px;
        background: URL("../../assets/images/emoji/happy.png") no-repeat center;
        background-size: contain;
        cursor: pointer;

        @media (max-width: $mobile) {
          width: 40px;
          height: 40px;
        }
      }
      .sad {
        width: 43px;
        height: 45px;
        background: URL("../../assets/images/emoji/sad.png") no-repeat center;
        background-size: contain;
        cursor: pointer;
        @media (max-width: $mobile) {
          width: 40px;
          height: 40px;
        }
      }
      .clap {
        width: 35px;
        height: 42px;
        background: URL("../../assets/images/emoji/clap.png") no-repeat center;
        background-size: contain;
        cursor: pointer;
        @media (max-width: $mobile) {
          width: 35px;
          height: 35px;
        }
      }
    }
  }
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  background: #2179f852;
  backdrop-filter: blur(3px);
  .cont {
    display: grid;
    place-items: center;
  }
  .icon {
    width: 500px;
    height: 500px;
    margin-top: -80px;
  }
  .btn {
    width: 350px;
    height: 125px;
    margin-top: -80px;
  }
  &.spectating-modal {
    .icon {
      background: URL("../../assets/images/spectate_icon_large.png") no-repeat center;
      background-size: contain;
    }
    .btn {
      background: URL("../../assets/images/spectate_bar.png") no-repeat center;
      background-size: contain;
    }
  }
  &.eliminated-modal {
    .icon {
      background: URL("../../assets/images/eliminated_icon.png") no-repeat center;
      background-size: contain;
    }
    .btn {
      background: URL("../../assets/images/eliminated_bar.png") no-repeat center;
      background-size: contain;
    }
  }
}

/*diffrent game content load*/

.eighties .game-page .main-area .podium {
  background-image: URL("../../assets/images/themes/theme_80s.png");
}
.nineties .game-page .main-area .podium {
  background-image: URL("../../assets/images/themes/theme_90s.png");
}
.noughties .game-page .main-area .podium {
  background-image: URL("../../assets/images/themes/theme_00s.png");
}
.movies .game-page .main-area .podium {
  background-image: URL("../../assets/images/themes/theme_hollywood.png");
}

/*custom mobile styles*/

@media (max-width: $bingo) {
  .modal .icon {
    width: 340px;
    height: 340px;
    margin-top: -70px;
  }
  .modal .btn {
    width: 270px;
    height: 95px;
    margin-top: -70px;
  }
}

@media (max-width: $mobile) {
  .game-page .footer .spectating .icon {
    display: block;
   
  }
  /* CSS fix to iOS devices */
  .layers {
    position: relative;
    height: -webkit-fill-available;
    height: fill-available;
    height: stretch;
  }
  .game-page {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    height: stretch;
  }
  @supports not (-webkit-touch-callout: none) {
    .layers,
    .game-page {
      min-height: 100vh;
    }
  }
  /* CSS fix to iOS devices */

  .game-page .top {
    padding: 25px 0px 0 15px;
  }
  .game-page .main-area {
    margin-top: 55px;
  }
  .game-page .top .answered,
  .game-page .top .remain {
    width: 10px;
    height: 15px;
    margin-right: 5px;
  }
  .game-page .top .current {
    font-size: 17px;
    margin-top: -2px;
  }

  .modal .icon {
    width: 320px;
    height: 320px;
    margin-top: -70px;
  }
  .modal .btn {
    width: 270px;
    height: 95px;
    margin-top: -70px;
  }
}

@media  (min-height: 720px)
{
  .layers, .game-page {
    min-height: 100vh;
    max-height: 100vh;
}
}

@media (max-width: $smallMobile) {
  .game-page .top {
    justify-content: flex-start;
  }
}

@media (max-width: 449px){

  .game-page .main-area .card-wrapper .cwrap {
    width: 350px;
}
.game-page .main-area .card-wrapper .cwrap .card.left .text, .game-page .main-area .card-wrapper .cwrap .card.right .text {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  width: 140px;
  height: 190px;
}


}