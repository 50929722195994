@import "../../Brakpoints.scss";

.game-end-page {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  flex-direction: column;
  .winning-banner {
    position: relative;
    height: 100px;

    @media (max-width: $bingo) {
      width: 360px;
      margin: 0 auto;
    }
    .img {
      position: absolute;
      top: -46px;
      left: 0;
      right: 0;
      background-image: URL("../../assets/images/winners_1.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      max-width: 892px;
      height: 206px;
      margin: 0 auto;

      display: flex;
      justify-content: center;
      align-items: flex-end;

      @media (max-width: $bingo) {
        background-image: URL("../../assets/images/mobile/winners_1.png");
        margin: 10px;
        position: relative;
        top: 0;
      }

      .winning-number {
        position: absolute;
        left: 50px;
        top: 83px;
        font-size: 70px;
        z-index: 2;
        font-weight: 600;

        @media (max-width: $bingo) {
          left: 40px;
          top: 7px;
        }
      }
      .content {
        z-index: 2;
        position: relative;
        display: flex;
        padding-bottom: 20px;
        padding-left: 20px;
        font-size: 20px;
        font-weight: 600;
        text-shadow: 0 0 3px #00000085;
        top: -25%;
        left: 90px;

        @media (max-width: $bingo) {
          top: -63%;
          left: 66px;
        }
        .text {
          color: #efd444;
          margin-right: 10px;
          //margin-top: 10px;
        }

        .number {
          color: white;
        }
      }
    }
  }

  .price-fund {
    margin-top: 50px;
    @media (max-width: $bingo) {
      margin-top: 30px;
      display: flex;
      width: 85%;
      background-size: 100% 100%;
      height: 75px;

      .img {
        background-size: contain;
        top: -33px;
        left: 0;
        width: 199px;
      }

      .content {
        flex-direction: column-reverse;
        font-size: 22px;
        align-self: center;
        margin-left: 210px;

        .text {
          font-size: 15px;
          margin-right: 0;
         
        }
      }
    }
  }
  .winning-label {
    position: absolute;
    color: white;
    font-weight: bold;
    left: 60px;
    bottom: 17px;
    transform: rotate(-5deg);
    font-size: 20px;

    @media (max-width: $bingo) {
      top: 83px;
      bottom: auto;
    }
  }

  .winners-content {
    width: 80%;
    margin: 0 auto;
    max-width: 1000px;
    transform: translate(0, 32%);
    height: 100%;
    max-height: 50%;
    // display: flex;
    // flex: 1;
    // justify-content: center;
    // align-items: center;
    // width: 100%;
    // padding: 0 50px;
    // margin: 0 auto;
    // margin-top: 11px;
    // margin-bottom: 25px;
    // padding-left: 55px;
    &.no-win {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      max-width: 1000px;
      width: 100%;
      padding: 0 50px;
      margin: 0 auto;
      margin-top: 11px;
      margin-bottom: 25px;
      padding-left: 55px;
      transform: none;
      height: auto;
    }
    // @media (max-width: $bingo) {
    //   display: grid;
    //   grid-template-columns: 1fr 1fr;
    //   grid-template-rows: 1fr 1fr 1fr;
    //   grid-template-areas:
    //     "winner1 . ."
    //     ". winner2 ."
    //     "winner3 . .";
    // }

    &.only-one {
      @media (max-width: $bingo) {
        display: flex;
      }

      .winner {
        .img {
          background-position-x: center;
        }
      }
    }

    .winner.one {
      grid-area: winner1;
    }
    .winner.two {
      grid-area: winner2;
    }
    .winner.three {
      grid-area: winner3;
    }

    .winner {
      max-width: 195px;
      margin: 10px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-self: center;
      align-self: center;

      @media (max-width: $bingo) {
        margin: -10%;
      }

      .img {
        background-image: URL("../../assets/images/winner_trophysmall.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 100%;
        min-height: 170px;
        background-position-x: right;
        // @media (max-width: $bingo) {
        //   min-height: 130px;
        // }
      }

      .name {
        background-image: URL("../../assets/images/winner_bar.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 50px;
        margin-top: -31px;
        display: flex;
        justify-content: center;
        align-items: center;

        .text {
          color: white;
          font-weight: bold;
        }
      }
    }

    .price {
      background-image: url("../../assets/images/winnings_bar3.svg");
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      width: 60%;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding: 5px;
      /* border-radius: 3px; */
      color: #f07319;
      font-weight: 700;
      background-size: 100%;
      z-index: 10000;
      height: 3rem;
      margin-top: -21px;
    }
    .no-winner {
      width: 400px;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;

      .circle-wrap {
        width: 400px;
        height: 400px;
        display: flex;
        flex-direction: column;
        position: absolute;
      }

      .inner-shadows {
        background-image: URL("../../assets/images/countdown_circle.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
      }

      .img {
        background-repeat: no-repeat;
        background-size: contain;
        background-image: URL("../../assets/images/rollover_countdown.png");
        width: 67%;
        height: 100%;
        z-index: 5;
        position: absolute;
        top: 143px;
        left: 66px;

        // transform-origin: middle middle;
      }

      .text {
        color: white;
        z-index: 3;
        position: relative;
        text-align: center;

        .h2 {
          font-size: 25px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        p {
          font-weight: normal;
          color: #be84b6;
          font-size: 14px;
        }
      }

      .start-img-background {
        background-image: URL("../../assets/images/gamestart_pattern.png");

        position: absolute;
        width: 510px;
        height: 510px;
        background-size: contain;
        background-repeat: no-repeat;
        top: 60px;
        left: -14%;

        @media (max-width: $bingo) {
          display: none;
        }
      }
    }
  }

  .play-again {
    // position: relative;
    // bottom: 70px;
    // cursor: pointer;
    // margin-top: 10px;
    // padding: 15px;

    position: relative;
    bottom: 25px;
    cursor: pointer;
    padding: 15px;
    max-width: 400px;
    margin: 10px auto 0 auto;
    width: 100%;

    .play-button {
      background-image: URL("../../assets/images/play_again_button.png");
      max-width: 335px;
      width: 100%;
      height: 66px;
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 3;
      position: relative;
      margin: 0 auto;
      .text {
        color: white;
        font-weight: 800;
        font-size: 20px;
        padding-right: 20px;
      }
    }
  }

  /*custom styles*/

  .game-end-page .play-button-wrap {
    padding-bottom: 0;
  }

  .footer {
    bottom: 25px;
  }

  .game-end-page .winners-content .winner {
    transform: scale(0.65) translateY(60px);
    opacity: 0;
    //transition: all .5s;
    animation: winner 1s ease-in-out forwards 1;
    &.one {
      animation-delay: 0.5s;
    }
    &.two {
      animation-delay: 1s;
    }
    &.three {
      animation-delay: 1.5s;
    }
  }
  @keyframes winner {
    from {
      transform: scale(0.65) translateY(60px);
      opacity: 0;
    }
    to {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }
  .game-end-page .winners-content.no-win {
    overflow: hidden;
  }

  @media (max-width: $extraLg) {
    .game-end-page .winners-content .no-winner .circle-wrap,
    .game-end-page .winners-content .no-winner {
      width: 350px;
      height: 350px;
    }
    .game-end-page .winners-content .no-winner .img {
      top: 125px;
      left: 58px;
    }
    .game-end-page .winners-content .no-winner .text .h2 {
      font-size: 20px;
      margin-bottom: 7px;
    }
    .game-end-page .winners-content .no-winner .text p {
      font-size: 12.8px;
     
    }
    .game-end-page .winners-content {
      margin-bottom: 0;
    }
    .game-end-page .play-button-wrap {
      margin-top: 0;
    }
    .game-end-page .winners-content .no-winner .start-img-background {
      width: 450px;
    }
  }

  @media (max-width: $bingo) {
    .game-end-page .winning-banner .img {
      justify-content: flex-end;
    }
    .game-end-page .winning-banner .img .content {
      left: 0;
      flex-direction: column-reverse;
      margin-right: 10px;
      top: -58%;
    }
    .game-end-page .winning-banner .img .content .number {
      font-weight: 900;
      font-size: 25px;
    }
    .game-end-page .winning-banner .img .content .text {
      text-transform: uppercase;
      font-size: 17px;
    }
    .game-end-page .winners-content .winner .img {
      min-height: 150px;
    }
  }

  @media (max-width: $mobile) {
    .game-end-page {
      height: calc(100vh - 60px);
      position: relative;
    }
    .footer {
      position:  relative !important;
      bottom: 45px !important;
      justify-content: flex-end;
      z-index: 12;
    }
    .footer .exit {
      padding-left: 20px;
    }
    .game-end-page .winners-content {
      align-content: center;
      gap: 20px 0;
    }
    .game-end-page .winners-content {
      margin-top: 55px;
      padding: 0 20px;
    }
   
  .play-again {
    bottom: 60px !important;
  }
  }
  @media (max-width: $smallMobile) {
    .game-end-page {
      height: auto;
      min-height: calc(100vh - 60px);
    }
    .footer {
      position: relative;
    }
    
  }

  @media (max-width: $verySmallMobile) {
    .game-end-page .play-button-wrap .play-button {
      width: 290px;
      height: 58px;
    }
    .footer .exit {
      padding-left: 15px;
    }
  }
}
