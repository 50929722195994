@import "../../Brakpoints.scss";

.entry-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px 1fr 3%;
  grid-template-areas:
    "top top"
    ". contentWrap"
    ". .";
  height: 100vh;

  @media (max-width: $bingo) {
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr 50px;
    grid-template-areas:
      "top"
      "contentWrap"
      ".";
  }
  .top-component {
    grid-area: top;
  }
  .content-wrap {
    grid-area: contentWrap;
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    align-items: end;
    justify-content: end;
    margin-right: 60px;
    max-height: 625px;
    align-self: center;
    justify-self: center;
    margin-top: -37px;
    z-index: 3;

    @media (max-width: $bingo) {
      align-self: flex-end;
      margin: 0 auto;
      padding: 20px;
    }

    .fiftyimg {
      background-image: URL("../../assets/images/5050_title.png");
      width: 133px;
      height: 92px;
      margin: 0 auto;
      background-repeat: no-repeat;

      @media (max-width: $bingo) {
        background-size: contain;
        margin: 0;
      }
    }
    .game-logo {
      background-image: URL("../../assets/images/musicquiz_title.png");
      max-width: 409px;
      width: 100%;
      height: 79px;
      margin: 0 auto;
      margin-top: 10px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .description {
      color: white;
      text-align: center;
      line-height: 20px;
      position: relative;
      z-index: 3;
    }
  }

  .music-image {
    background-image: URL("../../assets/images/themes/theme_music2.png");
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    background-size: contain;
    height: 100vh;
    background-repeat: no-repeat;
    background-position-x: -11%;
    background-position-y: 105px;
    touch-action: none;
    pointer-events: none;

    @media (max-width: $bingo) {
      bottom: auto;
      top: 0;
      background-position-x: -101%;
      background-position-y: 0;

      height: 46%;
      opacity: 0.5;
      // mask-image: -webkit-gradient(
      //   linear,
      //   left top,
      //   right bottom,
      //   color-stop(0, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 1)),
      //   color-stop(1, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 0)),
      //   color-stop(1, rgba(0, 0, 0, 0))
      // );
    }
  }

  .play-button-wrap {
    position: relative;
    margin-top: 140px;
    cursor: pointer;
    .cup-image {
      background-image: URL("../../assets/images/prizefund_startscreen.png");
      width: 600px;
      position: absolute;
      height: 206px;
      top: -112px;
      right: -90px;
      z-index: 2;
      pointer-events: none;
      top: -110px;
      right: -150px;

      @media (max-width: $bingo) {
        width: 130%;
        top: -67px;
        right: -61px;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .price-fund {
      z-index: 2;
      position: relative;
      display: flex;
      padding-bottom: 20px;
      padding-left: 20px;
      font-size: 20px;
      font-weight: 600;
      text-shadow: 0 0 3px #00000085;
      .text {
        color: #efd444;
      }

      .number {
        color: white;
      }
    }

    .starts-in {
      color: white;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-top: 15px;
      justify-content: center;
      .icon {
        background-image: URL("../../assets/images/timer_icon.png");
        width: 20px;
        height: 21px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }
      .content {
        display: flex;
        align-items: center;
        .text {
          font-size: 15px;
        }
        .number {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .play-button {
      background-image: URL("../../assets/images/play_button.png");
      max-width: 393px;
      width: 100%;
      height: 66px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 3;
      position: relative;
      margin: 0 auto;
      @media (max-width: $smallMobile) {
        background-size: contain;
      }
      .text {
        color: white;
        font-weight: 800;
        font-size: 20px;
        padding-right: 20px;
        position: absolute;
        top: 23px;

        @media (max-width: $smallMobile) {
          top: 17px;
        }
      }
    }
  }

  .footer {
    // position: absolute;
    // bottom: 15px;
    width: 100vw;
    z-index: 9999;
    display: flex;
    // justify-content: flex-end;
    align-items: center;
    @media (max-width: $mobile) {
      justify-content: flex-start;
      // justify-content: end;

      position: fixed;
      bottom: 15px;
    }

    .exit {
      padding-left: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      display: none;

      @media (max-width: $mobile) {
        display: block;
      }

      .icon {
        background: URL("../../assets/images/exit_button.png") no-repeat center;
        background-size: contain;
        width: 40px;
        height: 40px;
        position: relative;
        z-index: 2;
        cursor: pointer;
      }
    }
    // .reactions {
    //   width: 244px;
    //   height: 59px;
    //   background: URL("/assets/images/emoji/emoji_background.png") no-repeat
    //     right center;
    //   background-size: 100% 100%;
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: center;
    //   gap: 0 10px;
    //   padding: 0 22px;
    //   @media (max-width: $mobile) {
    //     width: 180px;
    //     height: 50px;
    //     grid-gap: 0 5px;
    //     gap: 0 5px;
    //     padding: 0 5px;
    //     background-size: 110% 100%;
    //   }
    // }
  }
}

/*emoji animation*/

// .footer .reactions{
//   .happy{
//     position: relative;
//     .clicked{
//       width: 43px;
//       height: 45px;
//       background: URL("/assets/images/emoji/happy.png") no-repeat center;
//       background-size: contain;
//       position: absolute;
//       left: 0;
//       top: 0;
//       animation: emojiAnimation 2.5s infinite linear;
//       backface-visibility: hidden;
//       @for $i from 1 through 5{
//         &.cl#{$i}{
//           animation-delay: calc(#{$i}s / .5);
//         }
//       }

//     }
//   }
//   .sad{
//     position: relative;
//     .clicked{
//       width: 43px;
//       height: 45px;
//       background: URL("/assets/images/emoji/sad.png") no-repeat center;
//       background-size: contain;
//       position: absolute;
//       left: 0;
//       top: 0;
//       animation: emojiAnimation2 2.5s infinite linear;
//       backface-visibility: hidden;
//       @for $i from 1 through 5{
//         &.cl#{$i}{
//           animation-delay: calc(#{$i}s / .5);
//         }
//       }
      
//     }
//   }
//   .clap{
//     position: relative;
//     .clicked{
//       width: 43px;
//       height: 45px;
//       background: URL("/assets/images/emoji/clap.png") no-repeat center;
//       background-size: contain;
//       position: absolute;
//       left: 0;
//       top: 0;
//       animation: emojiAnimation3 2.5s infinite linear;
//       backface-visibility: hidden;
//       @for $i from 1 through 5{
//         &.cl#{$i}{
//           animation-delay: calc(#{$i}s / .5);
//         }
//       }

//     }
//   }
// }

// @keyframes emojiAnimation {
//   10%{
//     left: 10px;
//     top: -20px;
//   }
//   30%{
//     left: 30px;
//     top: -60px;
//   }  
//   40%{
//     left: 40px;
//     top: -80px;
//   }
//   60%{
//     left: 00px;
//     top: -120px;
//     opacity: 0.7;
//   } 
//   70%{
//     left: 00px;
//     top: -140px;
//     opacity: 0.8;
//   }
//   80%{
//     left: -40px;
//     top: -160px;
//     opacity: 0.85;
//   } 
//   100%{
//     left:  0px;
//     top: -250px;
//     opacity: 0;
//   } 
// }


// @keyframes emojiAnimation2 {
//   10%{
//     left: -10px;
//     top: -10px;
//   }
//   30%{
//     left: 0px;
//     top: -40px;
//   }  
//   40%{
//     left: -10px;
//     top: -70px;
//   }
//   60%{
//     left: 30px;
//     top: -100px;
//     opacity: 0.7;
//   } 
//   70%{
//     left: 00px;
//     top: -130px;
//     opacity: 0.8;
//   }
//   80%{
//     left: 40px;
//     top: -150px;
//     opacity: 0.85;
//   } 
//   100%{
//     left:  0px;
//     top: -170px;
//     opacity: 0;
//   } 
// }


// @keyframes emojiAnimation3 {
//   10%{
//     left: -10px;
//     top: -20px;
//   }
//   30%{
//     left: 10px;
//     top: -50px;
//   }  
//   40%{
//     left: 0px;
//     top: -70px;
//   }
//   60%{
//     left: -30px;
//     top: -100px;
//     opacity: 0.7;
//   } 
//   70%{
//     left: 00px;
//     top: -130px;
//     opacity: 0.8;
//   }
//   80%{
//     left: 40px;
//     top: -150px;
//     opacity: 0.85;
//   } 
//   100%{
//     left:  0px;
//     top: -200px;
//     opacity: 0;
//   } 
// }


/*diffrent game content load*/

.eighties .entry-page .content-wrap .game-logo{
  background-image: URL("../../assets/images/desktop-titles/80s_title.png");
}
.eighties .entry-page .music-image{
  background-image: URL("../../assets/images/themes/theme_80s2.png");
}

.nineties .entry-page .content-wrap .game-logo{
  background-image: URL("../../assets/images/desktop-titles/90s_title.png");
}
.nineties .entry-page .music-image{
  background-image: URL("../../assets/images/themes/theme_90s2.png");
}

.noughties .entry-page .content-wrap .game-logo{
  background-image: URL("../../assets/images/desktop-titles/00s_title.png");
}
.noughties .entry-page .music-image{
  background-image: URL("../../assets/images/themes/theme_00s2.png");
}

.movies .entry-page .content-wrap .game-logo{
  background-image: URL("../../assets/images/desktop-titles/movies_title.png");
}
.movies .entry-page .music-image{
  background-image: URL("../../assets/images/themes/theme_hollywood2.png");
}


/*custom mobile styles*/

@media (max-width: 900px) {
  .entry-page .content-wrap {
    margin: 0 auto 150px;
  }
  .entry-page .music-image {
    background-position-x: 0;
    background-position-y: 0;
    height: 46%;
  }
}
@media (max-width: $mobile) {
/* CSS fix to iOS devices */   
   .layers {
    position: relative;
    height: -webkit-fill-available;
    height: fill-available; 
    height: stretch;
}
.entry-page {
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
}
@supports not (-webkit-touch-callout: none) {
  .layers, .entry-page {min-height: 100vh;}
}
/* CSS fix to iOS devices */ 

  .entry-page .content-wrap {
    margin: 0 auto 50px;
  }
  .entry-page .play-button-wrap .price-fund {
    padding-left: 10px;
    font-size: 16px;
    padding-bottom: 15px;
}
.entry-page .music-image{ 
  height: 60%;
  width: 150vw;
  left: -10vw;
}
.entry-page .play-button-wrap .cup-image{
  top: -52px;
}
.entry-page .play-button-wrap .starts-in .content .number {
  font-size: 17px;
}
}
@media (max-width: $smallMobile) {
  .entry-page .play-button-wrap {
    margin-top: 50px;
  }
  .entry-page .content-wrap .description {
    font-size: 12.8px;
  }
  .entry-page .content-wrap .game-logo {
    height: 60px;
  }
  .entry-page .content-wrap .fiftyimg {
    height: 70px;
    background-repeat: no-repeat;
  }
  .entry-page .play-button-wrap .starts-in {
    margin-top: 5px;
  }
}



 