.top-component {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  
  .info-icon {
    background-image: url("/assets/images/info_icon.svg");
    width: 29px;
    height: 29px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    text-shadow: 1px 1px 1px black;

    .separator {
      background-image: url("/assets/images/divider.png");
      width: 40px;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }

    .player-count {
      display: flex;
      align-items: center;

      .text {
        color: #ffec40;
        margin-right: 15px;
        font-weight: 600;
        font-size: 23px;
      }
      .icon {
        background-image: url("/assets/images/players_1.png");
        width: 20px;
        height: 31px;
      }
    }

    .prize-fund {
      color: white;
      font-weight: 600;
      font-size: 23px;
    }
  }
}
