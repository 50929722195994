@import "../Brakpoints.scss";
.prize-fund-component {
  .price-fund {
    background-image: url("/assets/images/prizefund_background2.png");
    width: 715px;
    height: 108px;
    background-repeat: no-repeat;
    align-self: flex-end;
    display: flex;
    position: relative;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: $bingo) {
      display: none;
    }

    .img {
      background-image: url("/assets/images/prizefund_large.png");
      background-repeat: no-repeat;
      width: 100%;
      height: 200px;
      position: absolute;
      top: -46px;
    }

    .content {
      display: flex;
      font-size: 20px;
      color: white;
      font-weight: 600;
      align-self: center;
      margin-left: 180px;
      .text {
        color: #fee219;
        margin-right: 30px;
      }
    }
  }
}
