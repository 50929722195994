@import "../../Brakpoints.scss";

.countdown {
  display: grid;
  justify-items: center;
  height: 100vh;
  grid-template-rows: 160px 1fr;

  @media (max-width: $bingo) {
    grid-template-rows: 60px 1fr minmax(100px, 230px);
  }

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }
  .img-mobile {
    background-image: URL("../../assets/images/mobile/prizefund_readyscreen.png");
    background-repeat: no-repeat;
    position: fixed;
    width: 100%;
    height: 100%;
    bottom: 0;
    background-size: contain;
    background-position: bottom;
    pointer-events: none;
    display: none;
    @media (max-width: $bingo) {
      display: block;
    }
  }

  .start-img-background {
    background-image: URL("../../assets/images/gamestart_pattern.png");

    position: absolute;
    width: 510px;
    height: 70%;
    background-size: contain;
    background-repeat: no-repeat;
    top: 60px;
    left: -20%;
    transition: opacity 1s ease-out;
    opacity: 0;

    &.show {
      opacity: 1;

      @media (max-width: $bingo) {
        display: none;
      }
    }
  }

  .top-component {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .mobile-pricefund {
    font-weight: bold;
    color: white;
    text-align: center;
    text-transform: uppercase;
    .money {
      font-size: 28px;
    }
    .text {
      color: #fee219;
    }

    display: none;

    @media (max-width: $bingo) {
      display: block;
      z-index: 2;
      text-shadow: 0 0 2px black;
    }
  }

  .clock {
    background-image: URL("../../assets/images/countdown_circle.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 350px;
    height: 350px;
    position: relative;
    align-self: center;
    justify-self: center;
    display: flex;
    padding: 54px;
    background-position-y: 0px;
    background-position-x: 0px;
    .text {
      color: white;
      font-weight: 500;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 23px;
    }
  }
}

.play-again {
  position: relative;
  z-index: 11;
}

.layers {
  position: relative;
}
.footer {
  position: absolute;
  bottom: 15px;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: $mobile) {
    justify-content: space-between;

    position: fixed;
    bottom: 15px;
  }

  .exit {
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    display: none;

    @media (max-width: $mobile) {
      display: block;
    }

    .icon {
      background: URL("../../assets/images/exit_button.png") no-repeat center;
      background-size: contain;
      width: 40px;
      height: 40px;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
  }
}
/*custom mobile styles*/
@media (max-width: $mobile) {
  /* CSS fix to iOS devices */   
  .layers {
    position: relative;
    height: -webkit-fill-available;
    height: fill-available; 
    height: stretch;
}
.footer {
 
  bottom: 60px !important;
}

.game-page {
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  height: stretch;
}

@supports not (-webkit-touch-callout: none) {
  .layers, .game-page {min-height: 100vh;}
}
/* CSS fix to iOS devices */ 
  .countdown .mobile-pricefund {
    transform: translateY(-7vh);
  }
  .countdown {
    height: calc(100vh - 50px);
  }
  .footer {
    justify-content: flex-end; 
  }
}
@media (max-width: $smallMobile) {
  .countdown .clock {
    width: 300px;
    height: 300px;
    padding: 48px;
  }
  .countdown .clock .text {
    font-size: 20px;
  }
  .countdown .mobile-pricefund {
    transform: translateY(-3vh);
  }
}
